import React from 'react';
import Masthead from '../../components/Masthead';

const Audit = () => {
	return (
		<div className='disciplines'>
			<Masthead heading1='Disciplines' heading2='Audit Discipline' />

			<div className='callout emphasized'>
				<p className='left-full'>
					<em>
						<b>The Introduction to School Safety and Security Audit Course</b>
					</em>{' '}
					is currently unavailable on the TxSSC online Learning Portal, while it
					is being revised. The completion of this course is a prerequisite to
					being accepted as a Registry Consultant through the Audit Discipline
					pathway.
				</p>
				<p className='left-full'>
					Due to the unavailability of the course, the Audit Discipline is
					currently unavailable as an option to be on the TxSSC Consultant
					Registry. The Audit Discipline will relaunch under a new system on
					June 1, 2025. In the meantime, you are encouraged to apply to the
					other available disciplines: Emergency Operations Plan, School
					Behavioral Threat Assessment, and Other Safety and Security.
				</p>
			</div>

			<p className='left-full'>
				An Audit Consultant may perform safety and security audits for public
				school districts, charter schools, and junior colleges to meet the
				standards established by the TxSSC and in compliance with Chapter 37 of
				the Texas Education Code (TEC). An Audit Consultant may suggest
				improvements to a district’s physical security infrastructure, as well
				as make suggestions or recommendations regarding findings from the
				audit. As part of the audit process the Audit Consultant may review
				whether a district has a Multi-Hazard Emergency Operations Plan (EOP)
				and whether the EOP sufficiently covers all the district hazards and
				functions. However, an Audit Consultant may not provide guidance,
				development, implementation, or consulting for EOP plans unless they are
				also registered for the Multi-Hazard Emergency Operations Plan
				Discipline.
			</p>

			{/* <div className='callout'>
				<h3>
					Audit Basic Training <em>(all applicants)</em>
				</h3>
				<ul>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://sslp.txssc.txstate.edu/'
							alt='Link to School Safety Learning Portal'
						>
							TxSSC Introduction to School Safety and Security Audits Course
						</a>{' '}
						available at the TxSSC online Learning Portal.
					</li>
				</ul>
				<h4 className='no-margin-bottom'>Evidence Required:</h4>
				<ul className='no-margin-top'>
					<li>Course certificates uploaded to Registry software</li>
				</ul>
			</div> */}

			<h3>Professional Qualifications</h3>
			<p className='left-full'>
				Consultants may choose to submit proof to add the following discipline
				qualifications to enhance their public profile. Once verified by TxSSC,
				consultant qualifications will be searchable and viewable on the
				Registry. While these qualifications are not mandatory, consultants may
				want to consider one or more of them as they may be used by districts to
				select consultants from the Registry.
			</p>

			<p className='left-full'>
				Districts are encouraged to use these additional qualifications to help
				decide which consultant may be best to perform services for the
				district.
			</p>

			<div className='callout'>
				<h3>Professional License</h3>
				<ul>
					<li>
						Texas Commission on Law Enforcement (TCOLE) Peace Officer or
						equivalent from another state or federal
					</li>
					<li>Architect licensed to practice in the United States</li>
				</ul>
				<h4 className='no-margin-bottom'>Evidence Required:</h4>
				<ul className='no-margin-top'>
					<li>License uploaded to Registry software</li>
				</ul>
			</div>

			<div className='callout'>
				<h3>Training and Certifications</h3>
				<ul>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://www.nasro.org/training/training-courses/'
							alt='National Association of School Resource Officers (NASRO) Basic Certificate'>
							National Association of School Resource Officers (NASRO) Basic
							Certificate
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://www.nasro.org/training/training-courses/'
							alt='National Association of School Resource Officers (NASRO) Advanced Certificate'>
							National Association of School Resource Officers (NASRO) Advanced
							Certificate
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://www.nrep.org/certifications/registered-environmental-manager-rem'
							alt='Registered Environmental Manager, National Registry of Environmental Professionals'>
							Registered Environmental Manager, National Registry of
							Environmental Professionals
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://www.tcfp.texas.gov/Services/GetPdf?filename=%2FManual%2FCurriculum%2Fcurriculum_2016_0601_chapter_4.pdf'
							alt='Basic Inspector Certification, Texas Commission on Fire Protection (TCFP), or equivalent'>
							Basic Inspector Certification, Texas Commission on Fire Protection
							(TCFP), or equivalent
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://teex.org/program/infrastructure-protection/'
							alt='Infrastructure Protection Certificate, Texas A&M Engineering Extension Service (TEEX)'>
							Infrastructure Protection Certificate, Texas A&M Engineering
							Extension Service (TEEX)
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://txssc.txstate.edu/events/sble-trainings/'
							alt='School-Based Law Enforcement (SBLE) Advanced, Texas School Safety Center (TxSSC)'>
							School-Based Law Enforcement (SBLE) Advanced, Texas School Safety
							Center (TxSSC)
						</a>
					</li>
					<li>
						Crime Prevention Through Environmental Design (CPTED) certificate
						(24 hours total)
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://texreg.sos.state.tx.us/public/readtac$ext.TacPage?sl=R&app=9&p_dir=&p_rloc=&p_tloc=&p_ploc=&pg=1&p_tac=&ti=37&pt=7&ch=221&rl=39'
							alt='Certified Crime Prevention Specialist, Texas Commission on Law Enforcement or equivalent'>
							Certified Crime Prevention Specialist, Texas Commission on Law
							Enforcement or equivalent
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://www.fletc.gov/training-program/physical-security-training-program'
							alt='Federal Law Enforcement Training Center (FLETC) Physical Security Training Course (PSTC) Certificate'>
							Federal Law Enforcement Training Center (FLETC) Physical Security
							Training Course (PSTC) Certificate
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://www.asisonline.org/certification/physical-security-professional/'
							alt='Physical Security Professional (PSP), American Society for Industrial Security (ASIS) certification'>
							Physical Security Professional (PSP), American Society for
							Industrial Security (ASIS) certification
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://www.asisonline.org/certification/associate-protection-professional-app/'
							alt='Associate Protection Professional (APP) ASIS certification'>
							Associate Protection Professional (APP) ASIS certification
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://www.asisonline.org/certification/certified-protection-professional-cpp/'
							alt='Certified Protection Professional (CPP) ASIS certification'>
							Certified Protection Professional (CPP) ASIS certification
						</a>
					</li>
				</ul>

				<h4 className='no-margin-bottom'>Evidence Required:</h4>
				<ul className='no-margin-top'>
					<li>Certificate uploaded to Registry software</li>
				</ul>
			</div>
		</div>
	);
};

export default Audit;
