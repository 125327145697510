import React, { useState, useEffect } from 'react';
import Pagination from '../../components/Pagination';
import ShowContractor from './ShowContractor';
import { useFetch } from '../../hooks/useFetch';
import useContractorFilter from '../../hooks/useContractorFilter';
import { API_BASE_URL } from '../../Constants';

const Search = () => {
	const [allContractors, setAllContractors] = useState([]);
	const [allContractorServices, setAllContractorServices] = useState([]);
	const [contractors, setContractors] = useState([]);
	const [filterOptions, setFilterOptions] = useState({});
	const [dateUpdated, setDateUpdated] = useState('');
	const [licenseFilter, setLicenseFilter] = useState('');
	const [nameFilter, setNameFilter] = useState('');
	const [organizationFilter, setOrganizationFilter] = useState('');
	const [otherFilter, setOtherFilter] = useState('');
	const [regionFilter, setRegionFilter] = useState('');
	const [serviceFilter, setServiceFilter] = useState('');
	const [showIndividualContractor, setShowIndividualContractor] =
		useState(false);
	const [toggleNameSort, setToggleNameSort] = useState(false);
	const [trainingCertFilter, setTrainingCertFilter] = useState('');

	const [currentPage, setCurrentPage] = useState(0);

	const { data, isPending, error } = useFetch(`${API_BASE_URL}/contractors`);

	if (error) {
		console.log(error);
	}

	const PER_PAGE = 20;
	const offset = currentPage * PER_PAGE;
	const pageCount = Math.ceil(contractors.length / PER_PAGE);
	const pages = Array.from(Array(pageCount).keys());

	const filterCriteria = {
		nameFilter,
		organizationFilter,
		serviceFilter,
		regionFilter,
		licenseFilter,
		otherFilter,
		trainingCertFilter,
	};

	useEffect(() => {
		if (data) {
			setFilterOptions(data.filter_opts || {});
			setDateUpdated(data.last_updated || '');
			setContractors(data.contractors || []);
			setAllContractors(data.contractors || []);
		}
	}, [data]);

	const filteredContractors = useContractorFilter(
		allContractors,
		filterCriteria
	);

	const arraysAreEqual = (array1, array2) => {
		if (array1.length !== array2.length) {
			return false;
		}
		for (let i = 0; i < array1.length; i++) {
			if (array1[i] !== array2[i]) {
				return false;
			}
		}
		return true;
	};

	// Update contractors list when filteredContractors changes
	useEffect(() => {
		if (!arraysAreEqual(filteredContractors, contractors)) {
			setContractors(filteredContractors);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredContractors]);

	// SET FILTERS ACCORDING TO TARGET VALUE
	const handleNameFilter = (e) => {
		let value = e.target.value;
		setNameFilter(value);
		setCurrentPage(0);
	};

	const handleOrganizationFilter = (e) => {
		let value = e.target.value;
		setOrganizationFilter(value);
		setCurrentPage(0);
	};

	const handleRegionFilter = (e) => {
		let value = e.target.value;
		setRegionFilter(value);
		setCurrentPage(0);
	};

	const handleServiceFilter = (e) => {
		let value = e.target.value;
		setServiceFilter(value);
		setCurrentPage(0);
	};

	const handleTrainingCertFilter = (e) => {
		let value = e.target.value;
		setTrainingCertFilter(value);
		setCurrentPage(0);
	};

	const handleLicenseFilter = (e) => {
		let value = e.target.value;
		setLicenseFilter(value);
		setCurrentPage(0);
	};

	const handleOtherFilter = (e) => {
		let value = e.target.value;
		setOtherFilter(value);
		setCurrentPage(0);
	};

	const handleResetFilters = (e) => {
		e.currentTarget.children[0].className += ' fa-spin';
		setTimeout(() => {
			window.location.reload();
		}, 1000);
		setCurrentPage(0);
	};

	const handleNameSort = () => {
		setToggleNameSort(!toggleNameSort);
		let asc, sorted;
		toggleNameSort ? (asc = true) : (asc = false);

		if (asc) {
			sorted = contractors.sort((a, b) => (a.Name > b.Name ? 1 : -1));
		} else {
			sorted = contractors.sort((a, b) => (a.Name < b.Name ? 1 : -1));
		}
		setContractors(sorted);
	};

	// contractorIndex returns each row in the table of contractors.
	const contractorIndex = contractors
		.slice(offset, offset + PER_PAGE)
		.map((contractor) => {
			let service = contractor.approved_disciplines;
			let email = contractor.contact_details.public_email_address;
			let name = `${contractor.public_last_name}, ${contractor.public_first_name}`;
			let phone = contractor.contact_details.public_phone_number;
			let org = contractor.contact_details.public_org_affiliation;
			return (
				<tr
					className='contractor'
					key={contractor.id}
					onClick={() => showContractor(contractor)}>
					<td>{name}</td>
					<td>{org}</td>
					<td>{email}</td>
					<td>{phone}</td>
					<td>{service}</td>
				</tr>
			);
		});

	const noResultsMessage =
		contractors.length === 0 ? (
			<tr>
				<td colSpan='5' className='no-results'>
					No contractors match your search criteria at this time. Please refresh
					or Reset Filters to try again.
				</td>
			</tr>
		) : null;

	const showContractor = (contractor) => {
		let filtered = allContractors.find((c) => c.id === contractor.id);
		setAllContractorServices(filtered);
		setShowIndividualContractor(!showIndividualContractor);
	};

	if (isPending) {
		return <div className='loading'>Loading</div>;
	}

	return (
		<>
			{data && (
				<div id='wrapper'>
					<div className='filters'>
						<div className='filter'>
							<input
								type='text'
								name='name'
								onChange={handleNameFilter}
								placeholder='Name'
								value={nameFilter}
								style={
									nameFilter.length > 0 ? { border: '3px solid #00507a' } : null
								}
							/>
						</div>

						<div className='filter'>
							<select
								aria-label='services'
								onChange={handleServiceFilter}
								value={serviceFilter}
								disabled={serviceFilter.length > 0 ? 'disabled' : null}
								style={
									serviceFilter.length > 0
										? { border: '3px solid #00507a' }
										: null
								}>
								<option value=''>Service(s) Provided</option>
								{filterOptions?.services?.map((credential, index) => {
									return (
										<option
											value={credential.split(' ')[0].toUpperCase()}
											key={index}>
											{credential}
										</option>
									);
								})}
							</select>
						</div>

						<div className='filter'>
							<select
								aria-label='regions'
								onChange={handleRegionFilter}
								value={regionFilter}
								disabled={regionFilter.length > 0 ? 'disabled' : null}
								style={
									regionFilter.length > 0 ||
									filterOptions?.esc_regions?.length < 1
										? { border: '3px solid #00507a' }
										: null
								}>
								<option value=''>Region(s) Served</option>
								{filterOptions?.esc_regions?.map((region, index) => {
									return (
										<option value={region} key={index}>
											{region}
										</option>
									);
								})}
							</select>
						</div>

						<div className='filter'>
							<select
								aria-label='trainings'
								onChange={handleTrainingCertFilter}
								value={trainingCertFilter}
								disabled={
									trainingCertFilter.length > 0 ||
									filterOptions?.training_and_certs?.length < 1
										? 'disabled'
										: null
								}
								style={
									trainingCertFilter.length > 0
										? { border: '3px solid #00507a' }
										: null
								}>
								<option value=''>Trainings/Certifications</option>
								{filterOptions?.training_and_certs?.map(
									(trainingCert, index) => {
										return (
											<option value={trainingCert} key={index}>
												{trainingCert}
											</option>
										);
									}
								)}
							</select>
						</div>

						<div className='filter remove-mobile'>
							<div className='refresh' onClick={handleResetFilters}>
								<i
									className='fa fa-refresh'
									aria-hidden='true'
									title='refresh filters'
								/>{' '}
								Reset Filters
							</div>
						</div>
						<div className='break' style={{ flexBasis: '100%' }}></div>

						{/* <div className='filter remove-mobile'></div> */}

						<div className='filter org'>
							<input
								type='text'
								name='organization'
								onChange={handleOrganizationFilter}
								placeholder='Organization'
								value={organizationFilter}
								style={
									organizationFilter.length > 0
										? { border: '3px solid #00507a' }
										: null
								}
							/>
						</div>

						<div className='filter other' style={{ marginTop: '10px' }}>
							{serviceFilter.includes('OTHER') ? (
								<select
									aria-label='other services'
									onChange={handleOtherFilter}
									value={otherFilter}
									disabled={otherFilter.length > 0 ? 'disabled' : null}
									style={
										otherFilter.length > 0 ||
										filterOptions?.other_services?.length < 1
											? { border: '3px solid #00507a' }
											: null
									}>
									<option value=''>Other</option>
									{filterOptions?.other_services?.map((other, index) => {
										return (
											<option value={other} key={index}>
												{other}
											</option>
										);
									})}
								</select>
							) : (
								<select aria-label='other services' disabled value='Other'>
									Other
									<option value='Other' disabled>
										Other
									</option>
								</select>
							)}
						</div>

						<div className='filter remove-mobile'></div>

						<div className='filter' style={{ marginTop: '10px' }}>
							<select
								aria-label='licenses'
								onChange={handleLicenseFilter}
								value={licenseFilter}
								disabled={
									licenseFilter.length > 0 ||
									filterOptions?.licenses?.length < 1
										? 'disabled'
										: null
								}
								style={
									licenseFilter.length > 0
										? { border: '3px solid #00507a' }
										: null
								}>
								<option value=''>License(s)</option>
								{filterOptions?.licenses?.map((license, index) => {
									return (
										<option value={license} key={index}>
											{license}
										</option>
									);
								})}
							</select>
						</div>

						<div className='filter mobile-refresh' onClick={handleResetFilters}>
							<i
								className='fa fa-refresh'
								aria-hidden='true'
								title='refresh filters'
							/>{' '}
							Reset Filters
						</div>

						<div className='filter'>
							<div className='date'>Last Updated: {dateUpdated}</div>
						</div>
					</div>

					{/* Table of all available contractors */}
					<table className='contractors not-printable'>
						<tbody>
							<tr>
								<th
									className='name'
									style={toggleNameSort ? { background: '#6a5638' } : null}
									onClick={handleNameSort}>
									Name{' '}
									{toggleNameSort ? (
										<i className='fa fa-chevron-down' aria-hidden='true' />
									) : (
										<i className='fa fa-chevron-up' aria-hidden='true' />
									)}
								</th>
								<th>Organization</th>
								<th>Email</th>
								<th className='table-phone'>Phone</th>
								<th>Service(s) Provided</th>
							</tr>
							{contractorIndex.length > 0 ? contractorIndex : noResultsMessage}
						</tbody>
					</table>

					{/* Show selected contractor by passing it's keys/values to ShowContractor Modal */}
					{showIndividualContractor ? (
						<ShowContractor
							showContractor={showContractor}
							setShowIndividualContractor={setShowIndividualContractor}
							contractor={allContractorServices}
						/>
					) : null}
					{pages.length > 1 && (
						<Pagination
							className='pagination-bar'
							currentPage={currentPage + 1}
							totalCount={contractors.length}
							pageSize={PER_PAGE}
							onPageChange={(page) => setCurrentPage(page - 1)}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default Search;
